.p-fluid .p-dropdown {
    display: flex !important;
    width: 100%;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: var(--primary-color-border);
}

.p-dropdown:not(.p-disabled):hover {
    border-color: var(--primary-color-border);
    box-shadow: none;

}

.p-dropdown.p-invalid.p-component {
    border-color: #e24c4c;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: var(--primary-color);
    background: var(--secondary-color-hover);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: var(--primary-color);
    background: var(--secondary-color-hover);
}

.p-input-filled .p-dropdown {
    background-color: white!important;
    display: flex;
    /* width: 100%; */
}

.p-input-filled .p-dropdown .disabled {
    background-color:lightgray!important;
}