.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: var(--secondary-color);
    color: var(--font-color-title);
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    background: var(--secondary-color-hover);
    color: var(--secondary-color-text);
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: var(--secondary-color-hover);
    color: var(--secondary-color-text);
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    background: var(--secondary-color);
    color: var(--font-color-title);
}
