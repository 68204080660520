.p-fieldset .p-fieldset-legend {
    color: var(--font-color-title);
    background-color: var(--secondary-color);
    padding: 0rem;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;

}



.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    color: var(--font-color-title);
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;


}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:hover {
    color: var(--secondary-color-text);
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background-color: var(--secondary-color-hover);
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
    box-shadow: none;
}