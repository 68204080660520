.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    box-shadow: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    box-shadow: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    background: var(--secondary-color-hover);
}
